import { FlexBox } from "@components/wrapper"
import { HARISENIN_PUBLIC_S3 } from "@constants/const/pictures"
import Helmet from "@helpers/Helmet"
import clsx from "clsx"
import Image from "next/image"

const NotFoundPage = () => {
  return (
    <>
      <Helmet title="Not Found" />
      <FlexBox className={clsx("w-4/5 h-[100vh] mx-auto")} justify="center" align="center">
        <div className="flex justify-center w-full lg:flex-row flex-col">
          <div className="mx-auto">
            <Image
              width={568}
              height={400}
              priority={true}
              src={`${HARISENIN_PUBLIC_S3}/pages/error-page_asset.png`}
              alt="image"
            />
          </div>

          <FlexBox className="mx-auto text-center lg:w-auto w-4/5" direction="col">
            <Image
              width={366}
              height={166}
              priority={false}
              src={`${HARISENIN_PUBLIC_S3}/pages/error-page_status-404.png`}
              alt="error 404"
              className="mx-auto mb-7"
            />
            <h2 className="font-semibold lg:text-2xl">
              Halaman yang kamu akses tidak bisa ditemukan.
            </h2>
            <p className="lg:text-base text-sm">
              Tidak bisa akses halaman? bisa jadi ada kesalahan nih. Silahkan periksa URL kamu atau{" "}
              <a href="https://www.harisenin.com" className="text-green font-medium">
                coba lagi
              </a>
              .
            </p>
          </FlexBox>
        </div>
      </FlexBox>
    </>
  )
}

export default NotFoundPage
